import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { Box, Heading, Text, Button as GButton } from 'grommet';
import { ILink } from '../utils/componentInterfaces';
import Button from './button';
import HtmlContent from './htmlContent';
import { createMarkup } from '../utils';
import { MaxWidth } from './maxWidth';
import { HeaderPopupContext } from '../context';
import ActionButtons from './actionButtons';
import { TagButton } from './TagButton';

interface BlockProps {
    content: string;
    link: ILink;
    linkOrPopup: 'popup' | 'link' | 'none' | 'both';
    buttonText: string;
}
export interface Props {
    style: string;
    heading: string;
    intro: string;
    afterText: string;
    blocks: BlockProps[];
}

const StyledContent = styled(Box)`
    text-align: center;
`;
const StyledButtonWrap = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    & > * {
        align-self: center;
    }
`;

// brand : Brand
// standout: Standout
// standard : Standard
const Cta: FC<Props> = ({ intro, style, heading, afterText, blocks }) => {
    const { onOpen } = useContext(HeaderPopupContext);

    let background: any = 'light-2';
    let className: any = 'light-2';
    let color = 'dark-1';

    if (style === 'brand') {
        background = { color: 'brand', dark: true };
        className = 'brand';
        color = 'light-1';
    } else if (style === 'standout') {
        background = 'accent-1';
        className = 'accent-1';
        color = 'light-1';
    }

    //
    return (
        <Box background={background} className={className}>
            <MaxWidth align="center">
                <Box pad={{ vertical: 'xlarge' }} width={{ max: '900px' }}>
                    <Heading level="2" margin="none" textAlign="center" color={color}>
                        {heading}
                    </Heading>
                    {intro && (
                        <Heading level="3" margin="none" textAlign="center" color={color}>
                            {intro}
                        </Heading>
                    )}

                    {blocks &&
                        blocks.map(({ content, link, linkOrPopup, buttonText }, i) => (
                            <Box pad={{ top: 'medium' }} key={i}>
                                {buttonText && linkOrPopup === 'link' && link && (
                                    <StyledButtonWrap>
                                        <Button
                                            color="accent-1"
                                            primary
                                            label={<Text color="white">{buttonText}</Text>}
                                            to={link.uri}
                                            reverse
                                        />
                                    </StyledButtonWrap>
                                )}
                                {buttonText && linkOrPopup === 'popup' && (
                                    <StyledButtonWrap>
                                        <TagButton
                                            primary
                                            label={
                                                <>
                                                    <Text color="white" textAlign="center">
                                                        {buttonText}
                                                    </Text>
                                                </>
                                            }
                                            onClick={onOpen}
                                            color="accent-1"
                                        />
                                    </StyledButtonWrap>
                                )}
                                {linkOrPopup === 'both' && (
                                    <StyledButtonWrap>
                                        <ActionButtons />
                                    </StyledButtonWrap>
                                )}
                                <StyledContent margin={{ bottom: 'medium', top: 'medium' }}>
                                    <Text as="div" color={color}>
                                        <HtmlContent
                                            textAlign="center"
                                            dangerouslySetInnerHTML={createMarkup(content)}
                                        />
                                    </Text>
                                </StyledContent>
                            </Box>
                        ))}
                    {afterText && (
                        <Heading level="3" margin="none" textAlign="center" color={color} fill>
                            {afterText}
                        </Heading>
                    )}
                </Box>
            </MaxWidth>
        </Box>
    );
};

export default Cta;
